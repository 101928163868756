import { Link } from "gatsby";
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-100">
      <nav
        className={`text-center md:block md:items-center w-full md:w-auto py-8`}
      >
        {[
          {
            route: `/about`,
            title: `About`,
          },
          {
            route: `/careers`,
            title: `Careers`,
          },
          {
            route: `/contact`,
            title: `Contact`,
          },
        ].map((link) => (
          <Link
            className="block mt-4 text-blue no-underline md:inline-block md:mt-0 md:ml-6"
            key={link.title}
            to={link.route}
          >
            {link.title}
          </Link>
        ))}
      </nav>
      <div className="pb-5 px-3 m-auto pt-5 text-gray-500 text-sm flex-col md:flex-row max-w-6xl">
        <div className="mt-2  text-sm text-center">
          <span className="text-sm">
            &copy; Copyright 2020 Emigh Livestock. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer >
  );
};

export default Footer;
